import React, { useEffect, useRef, useState } from 'react'
import InputSearch from '../UI/formElements/InputSearch'


import { ReactComponent as MessageIcon } from "../../assets/img/ico-message-2.svg"

import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import logo from "../../assets/img/logo.png"
import logoDark from "../../assets/img/logo-footer.png" 
import { getImageSrc } from '../../helpers/utils'
import { useClickOutside } from '../../hooks/useClickOutside'
import { logoutFetch } from '../../store/auth/authThunks'
import LoginModal from '../Modals/LoginModal'
import { Dropdown } from './Dropdown'

import { ReactComponent as PlaceholderIcon } from "../../assets/img/user-placeholder.svg"
import ForgotPassword from '../Modals/ForgotPassword'
import { getAllPages } from '../../store/pages/pagesSlice'
import { clearToken, setShowCreateModal, setShowLoginModal, setToken } from '../../store/auth/authSlice'
import { getNewNotificationsCountFeth } from '../../store/notifications/notificationsThunk'
import { getNewNotifications, setNewNotifications } from '../../store/notifications/notificationsSlice'
// import { socket } from '../../App'
import { getNewMessagesCount, setNewMessagesCount } from '../../store/chat/chatSlice'
import { toast } from 'react-toastify'
import { getUnreadCountFeth } from '../../store/chat/chatThunk'
import io from 'socket.io-client';
import { socket } from '../../App'
import GlobalSearch from './GlobalSearch'
import { CreateEveryModal } from '../Modals/CreateEveryModal'
import ThemSwith from './ThemSwith'
import Modal2FA from '../Modals/Modal2FA'

// const token = localStorage.getItem('authToken');

// export const socket = io(`${process.env.REACT_APP_API_URL}`, {
//     path: "/socket",
//     transports: ["websocket"],
//     auth: {
//         token: token,
//     },
// });
const routes = [
    {
        label: "Quiz",
        to: "/quiz",
    },
    {
        label: "Courses",
        to: "/courses",
    },
    {
        label: "Cases",
        to: "/cases",
    },
    {
        label: "Articles",
        to: "/articles",
    },
    {
        label: "Anatomy",
        to: "/anatomy",
    },
    {
        label: "Forums",
        to: "/forums",
    }
];

const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { me, isLoading, showLoginModal, token, showCreateModal, siteTheme } = useSelector((state) => state.auth)

    const location = useLocation();
    const pages = useSelector(getAllPages)
    const newNotifications = useSelector(getNewNotifications)
    const newMessages = useSelector(getNewMessagesCount)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false)
    const [forgorPasswordModal, setForgorPasswordModal] = useState(false)
    const [createModal, setCreateModal] = useState(false)
    const [modal2fa, setModal2fa] = useState(false)
    const menuRef = useRef(null)

    const handleMenuClose = () => {
        document.querySelector("body").classList.remove("lock")
        setIsMenuOpen(false)
    }
    useEffect(() => {
       
        socket.emit("subscribeToUnreadNotifications");
        socket.emit("subscribeToUnreadMessages");
        socket.emit("logout");
        socket.on('newUnreadMessagesCount', (msg) => {
            dispatch(setNewMessagesCount(msg?.unreadMessagesCount))
            if (!location.pathname.includes('chat')) {
                toast.success(`You've got message`)
            }
        });
        socket.on('unreadNotificationsCount', (msg) => {
            dispatch(setNewNotifications(msg?.count))
        });
        socket.on('subscribeToAll', (msg) => {
            if(me.id === msg) {
                clearToken()
                dispatch(setToken(null))
                navigate('/')
            }
        });
        return () => {
            socket.off('newUnreadMessagesCount');
            socket.off('unreadNotificationsCount');
            socket.off('subscribeToAll');
        };
    }, []);

    useEffect(() => {
        if (me) {
            dispatch(getNewNotificationsCountFeth())
            dispatch(getUnreadCountFeth())
        }
    }, [me]);
    const handleMenuToggle = () => {
        if (isMenuOpen) {
            handleMenuClose()
        } else {
            document.querySelector("body").classList.add("lock")
            setIsMenuOpen(true)
        }
    }
    const handleLogin = () => {
        setIsLoginPopupOpen(true)
    }
    const handleLogout = async () => {
        const res = await dispatch(logoutFetch()).unwrap()
        // if(res) {
            navigate('/')
        // }
        
    }
    useClickOutside(menuRef, handleMenuClose)
    return (
        <>
            <header className="header" ref={menuRef}>
                <div className="container">
                    <div className="header__body">
                        <div className={`header__burger ${isMenuOpen ? "active" : ""}`} onClick={handleMenuToggle}>
                            <span></span>
                        </div>
                        <nav className={`header__menu ${isMenuOpen ? "active" : ""}`}>
                            <GlobalSearch className={"header__search--mobile"} closeMenu={handleMenuToggle} />
                            <ul className="header__list">
                                {routes.map((route, index) => (
                                    <li key={index}>
                                        <NavLink to={route.to} onClick={handleMenuClose} className={({ isActive }) => `header__link ${isActive ? "active" : ""}`}>{route.label}</NavLink>
                                    </li>

                                ))}
                                <li className='hidden-desktop-links'>
                                    <NavLink to={"/materials"} onClick={handleMenuClose} className="header__link">
                                        <span className="info">Materials Library</span>
                                    </NavLink>
                                </li>
                                <li className='hidden-desktop-links'>
                                    <NavLink to={"/pricing"} onClick={handleMenuClose} className="header__link">
                                        <span className="info">Pricing Plan</span>
                                    </NavLink>
                                </li>
                                <li className='hidden-desktop-links'>
                                    <NavLink to={"/faq"} onClick={handleMenuClose} className="header__link">
                                        <span className="info">FAQ</span>
                                    </NavLink>
                                </li>
                                <li className='hidden-desktop-links'>
                                    <NavLink to={"/contact-us"} onClick={handleMenuClose} className="header__link">
                                        <span className="info">Contact Us</span>
                                    </NavLink>
                                </li>
                                <li className='hidden-desktop-links'>
                                    <NavLink to={"/about-us"} onClick={handleMenuClose} className="header__link">
                                        <span className="info">About Us</span>
                                    </NavLink>
                                </li>
                                <li className='hidden-desktop-links'>
                                    <NavLink to={"/events"} onClick={handleMenuClose} className="header__link">
                                        <span className="info">Event Calendar</span>
                                    </NavLink>
                                </li>
                                <li className='mobile-hidden-links'>
                                    <Dropdown
                                        wrapperClass={"dropdown-profile"}
                                        button={
                                            <button type='button' className="header__link">
                                                <span className="info">More</span>
                                                <span className="ico dropdown__arrow">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_41_950)">
                                                            <path d="M10.0965 4.35656C10.0681 4.28804 10.02 4.22946 9.95838 4.18824C9.89672 4.14702 9.82422 4.12502 9.75005 4.125H2.25005C2.17584 4.12494 2.10328 4.1469 2.04156 4.1881C1.97984 4.22931 1.93172 4.28789 1.90332 4.35645C1.87491 4.42501 1.86748 4.50046 1.88198 4.57324C1.89647 4.64602 1.93223 4.71287 1.98474 4.76531L5.73474 8.51531C5.76957 8.55018 5.81092 8.57784 5.85645 8.59671C5.90197 8.61558 5.95077 8.62529 6.00005 8.62529C6.04933 8.62529 6.09813 8.61558 6.14365 8.59671C6.18918 8.57784 6.23054 8.55018 6.26536 8.51531L10.0154 4.76531C10.0678 4.71284 10.1035 4.646 10.1179 4.57324C10.1323 4.50048 10.1249 4.42508 10.0965 4.35656Z" fill="currentColor" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_41_950">
                                                                <rect width="12" height="12" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </button>
                                        }
                                        body={
                                            <ul className='min-w-[180px]'>
                                                <li>
                                                    <Link to={"/materials"} onClick={handleMenuClose} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="info">Materials Library</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/pricing"} onClick={handleMenuClose} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="info">Pricing Plan</span>
                                                    </Link>
                                                </li>
                                                {pages && pages.map(item => (
                                                    <li key={item.id}>
                                                        <Link to={item.url} onClick={handleMenuClose} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                            <span className="info">{item.name}</span>
                                                        </Link>
                                                    </li>
                                                ))}
                                                {/* <li>
                                                    <Link to={"/contact-us"} onClick={handleMenuClose} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="info">Contact Us</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/about-us"} onClick={handleMenuClose} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="info">About Us</span>
                                                    </Link>
                                                </li> */}
                                                <li>
                                                    <Link to={"/events"} onClick={handleMenuClose} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="info">Event Calendar</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        }
                                    />
                                </li>
                            </ul>
                            {
                                !isLoading && me ?

                                    <div className="header__buttons header__buttons--mobile">
                                        <Link to={"/chat"} className="btn btn--transparent btn--sm btn--square rounded-full">
                                            <span className="ico">
                                                <MessageIcon />
                                            </span>
                                        </Link>
                                        <Link to={"/favorites"} className="btn btn--transparent btn--sm btn--square rounded-full">
                                            <span className="ico">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.58069 17.8779V6.41475C4.58069 4.89533 5.81242 3.6636 7.33183 3.6636H14.6682C16.1876 3.6636 17.4194 4.89533 17.4194 6.41475V17.8779C17.4195 18.0498 17.3235 18.2073 17.1706 18.2859C17.0178 18.3646 16.8338 18.3511 16.694 18.2511L11.2669 14.3738C11.1073 14.2596 10.8927 14.2596 10.7332 14.3738L5.30607 18.2502C5.16642 18.3501 4.98262 18.3637 4.82983 18.2852C4.67705 18.2068 4.58089 18.0496 4.58069 17.8779Z" stroke="#292929" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        </Link>
                                        <Link to={"/notifications"} className="btn btn--transparent btn--sm btn--square rounded-full relative">
                                            <span className="ico">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.5 12.6042L18.0649 14.169C18.2368 14.3409 18.3334 14.5741 18.3334 14.8172V15.5833C18.3334 16.0896 17.9229 16.5 17.4167 16.5H4.58335C4.07709 16.5 3.66669 16.0896 3.66669 15.5833V14.8172C3.66669 14.5741 3.76326 14.3409 3.93517 14.169L5.50002 12.6042V8.70834C5.50002 5.67078 7.96245 3.20834 11 3.20834V3.20834C12.4587 3.20834 13.8577 3.78781 14.8891 4.81926C15.9206 5.85071 16.5 7.24965 16.5 8.70834V12.6042Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.25 16.5V16.7292C8.25 18.2479 9.48122 19.4792 11 19.4792C12.5188 19.4792 13.75 18.2479 13.75 16.7292V16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            {newNotifications > 0 ? <span className='indicator'></span> : null}
                                        </Link>
                                    </div>
                                    :
                                    <div className="header__buttons header__buttons--mobile">
                                        <button onClick={handleLogin} className="btn btn--transparent btn--md radius-5">Login</button>
                                        <Link to={"/register"} className="btn btn--primary btn--md radius-5">Get Started</Link>
                                    </div>
                            }
                        </nav>
                        <Link to={"/"} className="logo">
                            <img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
                        </Link>
                        {
                            !isLoading && me ?
                                <div className="header__actions">
                                    <GlobalSearch />
                                    <div className="header__buttons">
                                        <Link to={"/chat"} className="btn btn--transparent btn--sm btn--square rounded-full relative">
                                            <span className="ico">
                                                <MessageIcon />
                                            </span>
                                            {newMessages > 0 ? <span className='indicator'></span> : null}
                                        </Link>
                                        <Link to={"/favorites"} className="btn btn--transparent btn--sm btn--square rounded-full">
                                            <span className="ico">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.58069 17.8779V6.41475C4.58069 4.89533 5.81242 3.6636 7.33183 3.6636H14.6682C16.1876 3.6636 17.4194 4.89533 17.4194 6.41475V17.8779C17.4195 18.0498 17.3235 18.2073 17.1706 18.2859C17.0178 18.3646 16.8338 18.3511 16.694 18.2511L11.2669 14.3738C11.1073 14.2596 10.8927 14.2596 10.7332 14.3738L5.30607 18.2502C5.16642 18.3501 4.98262 18.3637 4.82983 18.2852C4.67705 18.2068 4.58089 18.0496 4.58069 17.8779Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        </Link>
                                        <Link to={"/notifications"} className="btn btn--transparent btn--sm btn--square rounded-full relative">
                                            <span className="ico">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.5 12.6042L18.0649 14.169C18.2368 14.3409 18.3334 14.5741 18.3334 14.8172V15.5833C18.3334 16.0896 17.9229 16.5 17.4167 16.5H4.58335C4.07709 16.5 3.66669 16.0896 3.66669 15.5833V14.8172C3.66669 14.5741 3.76326 14.3409 3.93517 14.169L5.50002 12.6042V8.70834C5.50002 5.67078 7.96245 3.20834 11 3.20834V3.20834C12.4587 3.20834 13.8577 3.78781 14.8891 4.81926C15.9206 5.85071 16.5 7.24965 16.5 8.70834V12.6042Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.25 16.5V16.7292C8.25 18.2479 9.48122 19.4792 11 19.4792C12.5188 19.4792 13.75 18.2479 13.75 16.7292V16.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            {newNotifications > 0 ? <span className='indicator'></span> : null}
                                        </Link>
                                    </div>
                                    <Dropdown
                                        wrapperClass={"dropdown-profile"}
                                        autoClose={createModal}
                                        closeSelf={true}
                                        button={
                                            <span className="dropdown-profile__button cursor-pointer">
                                                {
                                                    me?.photo ?
                                                        <img className="ico ico--sm rounded-full" src={getImageSrc(me?.photo)} alt={me?.firstName} />
                                                        :
                                                        <span className='ico ico--sm rounded-full'>
                                                            <PlaceholderIcon />
                                                        </span>

                                                }

                                                <span className="ico dropdown__arrow">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_41_950)">
                                                            <path d="M10.0965 4.35656C10.0681 4.28804 10.02 4.22946 9.95838 4.18824C9.89672 4.14702 9.82422 4.12502 9.75005 4.125H2.25005C2.17584 4.12494 2.10328 4.1469 2.04156 4.1881C1.97984 4.22931 1.93172 4.28789 1.90332 4.35645C1.87491 4.42501 1.86748 4.50046 1.88198 4.57324C1.89647 4.64602 1.93223 4.71287 1.98474 4.76531L5.73474 8.51531C5.76957 8.55018 5.81092 8.57784 5.85645 8.59671C5.90197 8.61558 5.95077 8.62529 6.00005 8.62529C6.04933 8.62529 6.09813 8.61558 6.14365 8.59671C6.18918 8.57784 6.23054 8.55018 6.26536 8.51531L10.0154 4.76531C10.0678 4.71284 10.1035 4.646 10.1179 4.57324C10.1323 4.50048 10.1249 4.42508 10.0965 4.35656Z" fill="currentColor" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_41_950">
                                                                <rect width="12" height="12" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </span>
                                        }
                                        body={
                                            <ul className='w-[180px]'>
                                                <li className='drop-theme'>
                                                    <ThemSwith
                                                        iconsOnly={true}
                                                    />
                                                </li>
                                                <li>
                                                    <button type='button' onClick={() => setCreateModal(true)} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="ico">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_841_57729)">
                                                                    <path d="M3.66602 11H18.3327" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M11 3.6665V18.3332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_841_57729">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span className="info">Create</span>
                                                    </button>
                                                </li>
                                                <li>
                                                    <Link to={"/profile"} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="ico">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="7.33203" y="2.75" width="7.33333" height="7.33333" rx="3.66667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M18.3327 19.2498V17.0236C18.3327 14.9986 16.6911 13.3569 14.666 13.3569H7.33268C5.30764 13.3569 3.66602 14.9986 3.66602 17.0236V19.2498" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span className="info">My Profile</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/settings"} className="btn btn--transparent btn--lg radius-5 gap--xs">
                                                        <span className="ico">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.7505 9.24982C13.717 10.2164 13.717 11.7834 12.7505 12.75C11.7839 13.7165 10.2169 13.7165 9.2503 12.75C8.28375 11.7834 8.28375 10.2164 9.2503 9.24982C10.2169 8.28326 11.7839 8.28326 12.7505 9.24982" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.81332 11C4.81332 11.2723 4.83807 11.5445 4.87107 11.8085L3.4154 12.947C3.09273 13.2 3.00473 13.6519 3.21007 14.0067L4.5044 16.2461C4.70882 16.6008 5.14332 16.7503 5.52373 16.5981L6.82723 16.0747C7.08482 15.9711 7.37173 16.0123 7.60273 16.1654C7.8044 16.2993 8.0134 16.4221 8.22973 16.5321C8.47723 16.6577 8.6569 16.8823 8.69632 17.1573L8.89523 18.5442C8.95298 18.9493 9.3004 19.25 9.70923 19.25H12.2915C12.7003 19.25 13.0477 18.9493 13.1055 18.5442L13.3044 17.1582C13.3438 16.8832 13.5253 16.6568 13.7737 16.5321C13.9891 16.4239 14.1972 16.302 14.398 16.1691C14.6308 16.0151 14.9186 15.9711 15.1771 16.0756L16.4779 16.5981C16.8574 16.7503 17.2919 16.6008 17.4972 16.2461L18.7916 14.0067C18.9969 13.6519 18.9089 13.1991 18.5862 12.947L17.1306 11.8085C17.1636 11.5445 17.1883 11.2723 17.1883 11C17.1883 10.7278 17.1636 10.4555 17.1306 10.1915L18.5862 9.053C18.9089 8.8 18.9969 8.34808 18.7916 7.99333L17.4972 5.75392C17.2928 5.39917 16.8583 5.24975 16.4779 5.40192L15.1771 5.92442C14.9186 6.028 14.6308 5.98492 14.398 5.83092C14.1972 5.698 13.9891 5.57608 13.7737 5.46792C13.5253 5.34325 13.3438 5.11683 13.3044 4.84183L13.1064 3.45583C13.0486 3.05067 12.7012 2.75 12.2924 2.75H9.71015C9.30132 2.75 8.9539 3.05067 8.89615 3.45583L8.69632 4.84367C8.6569 5.11775 8.47632 5.34325 8.22973 5.46883C8.0134 5.57883 7.8044 5.70258 7.60273 5.8355C7.37082 5.98767 7.0839 6.02892 6.82632 5.92533L5.52373 5.40192C5.14332 5.24975 4.70882 5.39917 4.5044 5.75392L3.21007 7.99333C3.00473 8.34808 3.09273 8.80092 3.4154 9.053L4.87107 10.1915C4.83807 10.4555 4.81332 10.7278 4.81332 11V11Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span className="info">Settings</span>
                                                    </Link>
                                                </li>
                                                <li className="px-[14px]">
                                                    <hr className="hr" />
                                                </li>
                                                <li>
                                                    <button className="btn btn--transparent btn--lg radius-5 gap--xs" onClick={handleLogout}>
                                                        <span className="ico">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M10.0833 17.4148V6.87773C10.0833 6.24523 9.75792 5.65765 9.22167 5.32307L5.555 3.0314C4.334 2.26873 2.75 3.14598 2.75 4.58607V15.1222C2.75 15.7547 3.07542 16.3423 3.61167 16.6769L7.27833 18.9686C8.49933 19.7321 10.0833 18.854 10.0833 17.4148Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M19.25 10.0833H13.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M15.5833 8.25L13.75 10.0833L15.5833 11.9167" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M10.084 17.4165H13.7507C14.7636 17.4165 15.584 16.5961 15.584 15.5832V14.6665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M15.584 5.5V4.58333C15.584 3.57042 14.7636 2.75 13.7507 2.75H4.58398" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span className="info">Log Out</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        }
                                    />
                                </div>
                                :
                                <div className="header__actions">
                                    <InputSearch className={"header__search input--md"} />
                                    <div className="header__buttons">
                                        <button onClick={handleLogin} className="btn btn--transparent btn--md radius-5">Login</button>
                                        <Link to={"/register"} className="btn btn--primary btn--md radius-5">Get Started</Link>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </header>
            {
                (isLoginPopupOpen || showLoginModal) ?
                    <LoginModal
                        open={(isLoginPopupOpen || showLoginModal)}
                        handelClose={() => {
                            dispatch(setShowLoginModal(false))
                            setIsLoginPopupOpen(false)
                        }}
                        handelModal2Fa = {() => { setModal2fa(true)}}
                        handelForgor={() => {
                            dispatch(setShowLoginModal(false))
                            setIsLoginPopupOpen(false)
                            setForgorPasswordModal(true)
                        }}
                    />
                    :
                    null
            }
            {
                forgorPasswordModal && <ForgotPassword open={forgorPasswordModal} handelClose={() => setForgorPasswordModal(false)} />
            }
            {(createModal || showCreateModal) && <CreateEveryModal open={createModal || showCreateModal} handelClose={() => {
                setCreateModal(false)
                dispatch(setShowCreateModal(false))
            }}/>}
            {modal2fa && <Modal2FA open={modal2fa} handelClose={() => {
                setModal2fa(false)
            }}/>}
        </>
    )
}

export default Header