import { toast } from "react-toastify";
import { handleFileUploadWithProgress } from "../../helpers/utils";

const usersBase = '/api/casses';

export const cases = (instance) => ({
    async getAllCasses(qwery) {
        try {
            const { data } = await instance.get(`${usersBase}?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getCaseById({id, qwery}) {
        try {
            const { data } = await instance.get(`${usersBase}/${id}?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getRelatedQuizzesList(payload) {
        try {
            const { data } = await instance.get(`${usersBase}/quizzes-list?${payload}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }

    },
    async getRelatedArticlesList(payload) {
        try {
            const { data } = await instance.get(`${usersBase}/articles-list?${payload}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createCase(payload) {
        try {
            const { data } = await instance.post(`${usersBase}`, payload);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    // async addCaseMediaFilesByCaseId({ id, body }) {
    //     try {
    //         const { data } = await instance.post(`${usersBase}/media/${id}`, body);
    //         return data;
    //     }
    //     catch (error) {
    //         return {
    //             success: false,
    //             message: error.message
    //         }
    //     }
    // },

    async addCaseMediaFilesByCaseId({ id, body }) {
        const config = {
            url: `${usersBase}/media/${id}`,
            data: body,
            method: 'post',
        };
    
        return handleFileUploadWithProgress(instance, config);
    },
    async addCaseCommentsfilesByCaseId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/comments/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addSettingsToCaseByCaseId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/settings/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async favoriteCaseByIdToggle(id) {
        try {
            const { data } = await instance.post(`${usersBase}/favorite/${id}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeCaseByIdToggle(id) {
        try {
            const { data } = await instance.post(`${usersBase}/like/${id}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewCaseById(id) {
        try {
            const { data } = await instance.post(`${usersBase}/view/${id}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editCaseById({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editCaseMediaFilesByCaseId({ id, body }) {
        const config = {
            url: `${usersBase}/media/${id}`,
            data: body,
            method: 'put',
        };
    
        return handleFileUploadWithProgress(instance, config);
    },
    async editCaseCommentFilesByCaseId({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/comments/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editCaseSettingsByCaseId({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/settings/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteCaseById(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/${id}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});